import React from 'react'
import mobiscroll from '@mobiscroll/react'
import Router from './components/router'

import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import firebaseInit from './helper/firebase'

import './languages/i18n'
import './app.scss'

// set mobiscroll settings
mobiscroll.settings = {
  theme: 'ios' /* set global theme */
}
// config to use react router
mobiscroll.Route = Route

const router = props => {
  firebaseInit()
  return <Router {...props} />
}

export default withTranslation()(router)
