import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { createLoginHeader } from '../../helper/globalHelper'
import { apiList } from './helper'

rax.attach()

/* export const getTags = async (matchId) => {
  return axios.get(`${discovery.API_TAG}/Tag/${matchId}`, createLoginHeader())
} */

export const getTags = async (matchId) => {
  return apiList(`${discovery.API_TAG}/Tag/${matchId}`, undefined, await createLoginHeader())
}

export const getAiTags = async (matchId) => {
  return apiList(`${discovery.API_TAG}/AiTagsOfVideo/${matchId}`, undefined, await createLoginHeader())
}

export const deleteTag = async (matchId, tagId, userInfo) => {
  const callAs = searchAndSortGetCallAs(['admin', 'contractOwner', 'cameraController', 'tagger'], userInfo)
  return axios.delete(`${discovery.API_TAG}/Tag/${matchId}/${tagId}${callAs}`, await createLoginHeader())
}

export const deleteAiTag = async (matchId, tagId, userInfo) => {
  const callAs = searchAndSortGetCallAs(['admin', 'contractOwner', 'cameraController', 'tagger'], userInfo)
  return axios.delete(`${discovery.API_TAG}/deleteAiTag/${matchId}/${tagId}${callAs}`, await createLoginHeader())
}

export const postSwapTags = async (matchId, userInfo) => {
  const callAs = searchAndSortGetCallAs(['admin', 'contractOwner', 'cameraController', 'tagger'], userInfo)
  return axios.post(`${discovery.API_TAG}/Swap/${matchId}${callAs}`, undefined, await createLoginHeader())
}

export const postTag = async (body, matchId, tagId, userInfo) => {
  const callAs = searchAndSortGetCallAs(['admin', 'contractOwner', 'cameraController', 'tagger'], userInfo)
  const postUrl = !tagId ? `${discovery.API_TAG}/Tag/${matchId}${callAs}` : `${discovery.API_TAG}/Tag/${matchId}/${tagId}${callAs}`
  return axios.post(postUrl, body, await createLoginHeader())
}

export function searchAndSortGetCallAs(roles, userInfo) {
  return `?callAs=${searchAndSortGetRoles(roles, userInfo)}`
}

export function searchAndSortGetRoles(roles, userInfo) {
  const userRoles = userInfo?.role
  let callAs = ''

  for (let index = 0; index < roles.length; index++) {
    const userHasRole = userRoles.indexOf(roles[index]) > -1
    if (userHasRole) {
      callAs = `${roles[index]}`
      index = roles.length
    }
  }
  return callAs
}

export const postAiTagConfirmDenied = async (
  // body: { state: AiTagState.confirmed | AiTagState.denied | AiTagState.unconfirmed },
  state,
  matchId,
  tagId,
  userInfo,
  clubALeft
) => {
  const body = { state: state, clubALeft: clubALeft }
  const callAs = searchAndSortGetCallAs(['admin', 'contractOwner', 'cameraController', 'tagger'], userInfo)
  return axios.post(`${discovery.API_TAG}/AiTagConfirmDenied/${matchId}/${tagId}${callAs}`, body, await createLoginHeader())
}