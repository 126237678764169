import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const Axios = require('axios')
let user, pw

export function setLogin (user_, pw_) {
  user = user_
  pw = pw_
}

/**
 * Ads a postfix text bevor the last '.'
 * @param {string} uri
 * @param {string} postfix
 */
export function changeUriPostfix (uri, postfix) {
  var lastPoint = uri.lastIndexOf('.')
  var pre = uri.substring(0, lastPoint)
  var post = uri.substring(lastPoint)
  return pre + postfix + post
}

export async function createLoginHeader (headers = {}) {
  headers.Authorization = await getAuthorization()
  headers['Content-Type'] = 'application/json'
  return { headers }
}

export const getAuthorization = async () => {
  const checkUser = await firebase.auth().currentUser
  if (checkUser) {
    return 'Bearer ' + await firebase.auth().currentUser.getIdToken()
  }
}

export function escapeUmlaute (url) {
  url = url.replace(/ä/g, '%c3%b4')
  url = url.replace(/ö/g, '%c3%b6')
  url = url.replace(/ü/g, '%c3%bc')
  return url
}

export async function apiGet (url) {
  try {
    url = escapeUmlaute(url)
    return (await Axios.get(url, createLoginHeader())).data
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function apiDelete (url) {
  try {
    url = escapeUmlaute(url)
    return (await Axios.delete(url, createLoginHeader())).data
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function apiPost (url, body) {
  try {
    url = escapeUmlaute(url)
    return (await Axios.post(url, body, createLoginHeader())).data
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 *
 * @param {string} url | same as get but expects response to be a storageLayer style list call
 * handles
 * @param {int} minEntrys | minimum amount of entry to fetch.Undefined for all entrys
 */
export async function apiList (url, minEntrys, retrys = 20) {
  let dataArray = []
  let response
  url = escapeUmlaute(url)
  const header = createLoginHeader()
  try {
    response = (await Axios.get(url, header)).data
    dataArray = dataArray.concat(response.data)
    while (response.nextToken) {
      // Replace Slashes
      response = (await Axios.get(url + response.nextToken.replace(/\//g, '%2F'), header)).data
      dataArray = dataArray.concat(response.data)
      if (minEntrys && minEntrys < dataArray.length) {
        break
      }
    }
    return dataArray
  } catch (err) {
    retrys -= 1
    if (retrys > 0) {
      await new Promise((resolve, reject) => {
        setTimeout(resolve, 500)
      })
      return exports.apiList(url, minEntrys, retrys)
    }
    console.warn('no list result', url)
    return Promise.reject(err)
  }
}

// Creates a v1 conform club definition
export async function ClubFromDB (dbClub) {
  var Club = {
    id: dbClub.RowKey,
    name: dbClub.name,
    city: dbClub.city.toLowerCase(),
    latitude: dbClub.latitude,
    longitude: dbClub.longitude,
    thumbnail: dbClub.thumbnail,
    location: dbClub.location,
    view: dbClub.view ? dbClub.view : -1,
    teams: dbClub.teams ? dbClub.teams : -1,
    videos: dbClub.videos ? dbClub.videos : -1
  }
  return Club
}

export function generateKey () {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function fromUnitVector (n) {
  return Math.round(n * 255)
}
export function toUnitVector (n) {
  return n / 255.0
}

// main algorithm, it executes a callback on every color it finds
export function getColors (lottie, cb) {
  if (lottie) {
    lottie.forEach((layer, i) => {
      if (layer.shapes) {
        layer.shapes.forEach((shape, j) => {
          if (shape.it) {
            shape.it.forEach((prop, k) => {
              if (['fl', 'st'].includes(prop.ty)) {
                const color = prop.c.k

                // eslint-disable-next-line
                let [r, g, b, a] = color;

                const rgbr = fromUnitVector(r)
                const rgbg = fromUnitVector(g)
                const rgbb = fromUnitVector(b)

                if (cb) {
                  cb(null, { r, g, b, rgbr, rgbg, rgbb })
                }
              }
            })
          }
        })
      }
    })
  }
}
