import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import registerServiceWorker from './registerServiceWorker'
import * as Discovery from '@soccerwatch/discovery'

/* import mobiscroll css */
import '@mobiscroll/react/dist/css/mobiscroll.min.css'

// Discovery.init(process.env.REACT_APP_Environment || 'prod', true)
Discovery.init('prod', true)
  .then(() => {
    process.env.API_ADDITIONAL = Discovery.API_ADDITIONAL
    process.env.API_USER = Discovery.API_USER
    ReactDOM.render(<App />, document.getElementById('root'))
    registerServiceWorker()
  })
  .catch(err => {
    alert(err)
    console.error(err)
  })
