import React from 'react'
import i18n from 'i18next'
import Timemenu from './timeMenu'

import './editoroverlay.scss'

export default class Editoroverlay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  tennisTimeCalc (time) {
    const tiebreak = time.tiebreak
    let pointGuest, pointHome
    if (tiebreak) {
      pointGuest = '' + time.tiebreakGuest
      pointHome = '' + time.tiebreakHome
    } else {
      if (time.pointGuest <= 3 && time.pointHome <= 3) {
        pointGuest = '' + this.tennisPointToStrangeNumber(time.pointGuest)
        pointHome = '' + this.tennisPointToStrangeNumber(time.pointHome)
      } else if (time.pointGuest === time.pointHome) {
        pointGuest = '' + 40
        pointHome = '' + 40
      } else if (time.pointGuest > time.pointHome) {
        pointGuest = 'AD'
        pointHome = '' + 40
      } else if (time.pointGuest < time.pointHome) {
        pointGuest = '' + 40
        pointHome = 'AD'
      }
    }

    return { pointGuest, pointHome }
  }

  tennisPointToStrangeNumber (point) {
    switch (point) {
      case 0:
        return 0
      case 1:
        return 15
      case 2:
        return 30
      case 3:
        return 40
      default:
        return 0
    }
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  renderTennis = (currentTagTime) => {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 10px' }}>
          <div style={{ display: 'flex' }}>
            {currentTagTime && currentTagTime.serveHome ? <div className='tActive' /> : <div className='t' />}
            <div style={{ paddingRight: '16px' }}>Spieler A</div>
            <div style={{ display: 'flex' }}>
              <div>{currentTagTime && currentTagTime.scoreA ? currentTagTime.scoreA : 0}  | </div>
              <div style={{ margin: '0 4px' }}>{currentTagTime && currentTagTime.gamesHome ? currentTagTime.gamesHome : 0} | </div>
              <div>{currentTagTime && currentTagTime.pointHome ? this.tennisTimeCalc(currentTagTime).pointHome : 0}</div>
            </div>
          </div>
          <div>Tirbreak: 0</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 10px' }}>
          <div style={{ display: 'flex' }}>
            {currentTagTime && currentTagTime.serveGuest ? <div className='tActive' /> : <div className='t' />}
            <div style={{ paddingRight: '16px' }}>Spieler B</div>
            <div style={{ display: 'flex' }}>
              <div>{currentTagTime && currentTagTime.scoreB ? currentTagTime.scoreB : 0} | </div>
              <div style={{ margin: '0 4px' }}>{currentTagTime && currentTagTime.gamesGuest ? currentTagTime.gamesGuest : 0} | </div>
              <div>{currentTagTime && currentTagTime.pointGuest ? this.tennisTimeCalc(currentTagTime).pointGuest : 0}</div>
            </div>
          </div>
          <div>Tirbreak: 0</div>
        </div>
      </div>
    )
  }

  renderVolleyball = (currentTagTime) => {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 10px' }}>
          <div style={{ display: 'flex' }}>
            {currentTagTime && currentTagTime.serveHome ? <div className='tActive' /> : <div className='t' />}
            <div style={{ paddingRight: '11px' }}>Club Heim</div>
            <div style={{ display: 'flex' }}>
              <div>{currentTagTime && currentTagTime.scoreA ? currentTagTime.scoreA : 0}  | </div>
              <div style={{ margin: '0 4px' }}>{currentTagTime && currentTagTime.pointHome ? currentTagTime.pointHome : 0}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 10px' }}>
          <div style={{ display: 'flex' }}>
            {currentTagTime && currentTagTime.serveGuest ? <div className='tActive' /> : <div className='t' />}
            <div style={{ paddingRight: '16px' }}>Club Gast</div>
            <div style={{ display: 'flex' }}>
              <div>{currentTagTime && currentTagTime.scoreB ? currentTagTime.scoreB : 0} | </div>
              <div style={{ margin: '0 4px' }}>{currentTagTime && currentTagTime.pointGuest ? currentTagTime.pointGuest : 0}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  render () {
    const { matchInfo, currentTagTime, eventType } = this.props
    let timer = '00:00'

    const videoTypeTennis = eventType === 'tennis' || eventType === 'padeltennis'
    const videoTypevolleyball = eventType === 'volleyball'

    if (currentTagTime) {
      // var milliseconds = parseInt((currentTagTime.currentTime % 1000) / 100)
      var seconds = Math.floor((currentTagTime.currentTime / 1000) % 60)
      var minutes = Math.floor((currentTagTime.currentTime / (1000 * 60)))
      // var hours = Math.floor((currentTagTime.currentTime / (1000 * 60 * 60)) % 24)
      // hours = (hours < 10) ? '0' + hours : hours
      minutes = (minutes < 10) ? '0' + minutes : minutes
      seconds = (seconds < 10) ? '0' + seconds : seconds
      timer = minutes + ':' + seconds
    }

    let shotClock = (((this.props.currentTagTime || {}).clocks || {}).shotClock || {}).time
    try {
      shotClock = (shotClock === undefined) ? shotClock : parseInt(shotClock / 1000)
    } catch (e) { console.error(e) }

    return (
      <div className='editoroverlay'>
        <div className='goalTimerOverlay'>
          {videoTypeTennis ? null : (
            <div className='goalsCounter'>
              <div className='club' title={i18n.t('tagControl.home') + ' : ' + matchInfo.clubAName}>
                {matchInfo.clubAName || 'Home'}
              </div>
              <div className='counter'>
                {currentTagTime && currentTagTime.scoreA ? currentTagTime.scoreA : 0}
                {' : '}
                {currentTagTime && currentTagTime.scoreB ? currentTagTime.scoreB : 0}
              </div>
              <div className='club' title={i18n.t('tagControl.guest') + ' : ' + matchInfo.clubBName}>
                {matchInfo.clubBName || 'Guest'}
              </div>
            </div>
          )}

          <div className='goalsCounter matchTimer'>
            {videoTypeTennis || videoTypevolleyball ? (
              <>
                {videoTypeTennis ? this.renderTennis(currentTagTime) : null}
                {videoTypevolleyball ? this.renderVolleyball(currentTagTime) : null}
              </>
            ) : (
              <div>

                {'foul '}
                {currentTagTime && currentTagTime.foulsHome ? currentTagTime.foulsHome : 0}
                {'-'}
                {currentTagTime && currentTagTime.foulsGuest ? currentTagTime.foulsGuest : 0}
                {' | time '}
                {timer}
                {' | round '}
                {this.props.currentTagTime && this.props.currentTagTime.round !== undefined ? this.props.currentTagTime.round : '-'}

                {shotClock !== undefined && ' | shot '}
                {shotClock !== undefined && shotClock}

              </div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'nowrap' }}>
            <div>
              {this.props.currentTagTime?.clocks?.penalty?.map((panalty, index) => {
                if(panalty.team === 'home') {
                  return (
                    <div key={index}>
                      {this.millisToMinutesAndSeconds(panalty.time)}
                    </div>
                  )
                }
              })}
            </div>
            <div>
              {this.props.currentTagTime?.clocks?.penalty?.map((panalty, index) => {
                if(panalty.team === 'guest') {
                  return (
                    <div key={index}>
                       {this.millisToMinutesAndSeconds(panalty.time)}
                    </div>
                  )
                }
              })}
            </div>
          </div>

        </div>
        <div style={{ flexGrow: 1 }} />
        <Timemenu
          matchTags={this.props.matchTags}
          matchId={this.props.matchId}
          getVideoTagsReload={this.props.getVideoTagsReload}
          videoRef={this.props.videoRef}
          eventType={this.props.eventType}
          cameraStart={this.props.cameraStart}
          remote={this.props.remote}
          user={this.props.user}
        />
      </div>
    )
  }
}
