import React from 'react'
import i18n from 'i18next'
import mobiscroll from '@mobiscroll/react'

import { Link } from 'react-router-dom'
import { EVENTSETTINGS, EVENTTYPELIST, EVENTGROUPS } from '../../tagConfig'

import { postTag } from '../../../../api/api-tag'

import './tagcontrol.scss'

export default class Tagcontrol extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categorys: undefined,
      categoryActive: undefined,
      disableButtons: false,
      eventType: EVENTSETTINGS[this.props.eventType] ? this.props.eventType : 'soccer'
    }

    this.handleCategory = this.handleCategory.bind(this)
  }

  componentDidMount () {
    const categorys = {}
    let categorysMerge = {}

    EVENTSETTINGS[this.state.eventType].eventCategory.map((itm) => {
      const types = EVENTTYPELIST.map((event) => {
        if (itm.eventTypes.indexOf(event.eventType) > -1) {
          return event
        }
        return null
      }).filter((itm) => itm !== null)
      categorys[itm.nameEn.toLocaleLowerCase()] = types
      return null
    })

    Object.keys(categorys).forEach(function (name) {
      let groupMerge = {}
      const arrayGroup = categorys[name]
      categorysMerge = { ...categorysMerge, [name]: { single: [], group: [] } }

      arrayGroup.map((event) => {
        // search single typeEvents
        if (!event.group) {
          categorysMerge[name].single.push(event)
          return null
        }

        // search group typeEvents
        const group = EVENTGROUPS[event.group - 1]
        if (event.club.toLocaleLowerCase() === 'home') {
          groupMerge.home = event
        } else {
          groupMerge.guest = event
        }

        if (groupMerge.home && groupMerge.guest) {
          groupMerge.id = group.id
          groupMerge.name = group.name
          groupMerge.nameEn = group.nameEn
          categorysMerge[name].group.push(groupMerge)
          groupMerge = {}
        }

        return null
      })
    })

    this.setState({
      categorys: categorysMerge,
      categoryActive: Object.keys(categorysMerge)[0]
    })
  }

  handleCategory (value) {
    this.setState({
      categoryActive: value.toLocaleLowerCase()
    })
  }

  renderCategoryMenu () {
    const content = EVENTSETTINGS[this.state.eventType].eventCategory.map((itm, index) => {
      return (
        <button key={index} onClick={() => this.handleCategory(itm.nameEn)}>
          {i18n.t('eventCategory.' + this.state.eventType + '.' + itm.id)}
        </button>
      )
    })
    return content
  }

  createSingleButton () {
    const { categorys, categoryActive } = this.state
    const checkState = categorys && categoryActive

    if (!checkState) {
      return
    }

    const button = categorys[categoryActive].single.map((groupInfo, index) => {
      const disableButtons = this.state.disableButtons
      return (
        <div key={index} className='content-singleBtn'>
          <button
            disabled={disableButtons}
            style={disableButtons ? { backgroundColor: 'black', cursor: 'default' } : {}}
            onClick={() => {
              this.handlePostTag(groupInfo)
            }}
          >
            {i18n.t('eventType.' + groupInfo.eventType)}
          </button>
        </div>
      )
    })

    return button
  }

  createDoubleButton () {
    const { categorys, categoryActive } = this.state
    const checkState = categorys && categoryActive

    if (!checkState) {
      return
    }

    const button = categorys[categoryActive].group.map((groupInfo, index) => {
      const disableButtons = this.state.disableButtons
      return (
        <div key={index} className='content-doubleBtn'>
          <p>{i18n.t('eventGroups.' + groupInfo.id)}</p>
          <button
            disabled={disableButtons}
            title={i18n.t('eventType.' + groupInfo.home.eventType)}
            className={disableButtons ? 'left disabled' : 'left'}
            onClick={() => {
              this.handlePostTag(groupInfo.home)
            }}
          />
          <button
            disabled={disableButtons}
            title={i18n.t('eventType.' + groupInfo.guest.eventType)}
            className={disableButtons ? 'right disabled' : 'right'}
            onClick={() => {
              this.handlePostTag(groupInfo.guest)
            }}
          />
        </div>
      )
    })
    // {i18n.t('tagControl.home')}  {i18n.t('tagControl.guest')}
    return button
  }

  handlePostTag (eventObj) {
    let timestampCalc

    if (!this.props.remote) {
      // onsite
      const cameraStart = new Date(this.props.cameraStart).getTime()
      const nowDate = new Date().getTime()
      const minCalc = Math.round((nowDate - cameraStart) / 1000)
      timestampCalc = minCalc
    } else {
      // remote
      timestampCalc = Math.round(this.props.videoRef.currentTime)
    }

    // check soccer tags for instant highlight
    let highlight = 0
    const highlightTags = [0, 1, 2, 3, 6, 7, 10, 11] // eventType list for direkt highlight
    if (this.state.eventType === 'soccer' && highlightTags.includes(eventObj.eventType)) {
      highlight = 1
    }

    const body = {
      eventType: eventObj.eventType,
      timestamp: timestampCalc,
      score: eventObj.score,
      startClock: eventObj.startClock,
      stopClock: eventObj.stopClock,
      clockReverse: eventObj.clockReverse,
      clockType: eventObj.clockType,
      startClockWithSeconds: eventObj.startClockWithSeconds,
      level: highlight,
      tiebreak: eventObj.tiebreak
    }

    this.setState({
      disableButtons: true
    })

    postTag(body, this.props.matchId, undefined, this.props.user).then((res) => {
      // TODO: text change for i18n
      mobiscroll.toast({ message: 'Tag hinzugefügt: ' + eventObj.name, display: 'bottom' })
      this.props.getVideoTagsReload()
      this.setState({
        disableButtons: false
      })
    })
      .catch((err) => {
        this.setState({
          disableButtons: false
        })
        mobiscroll.toast({ message: err, display: 'bottom', color: 'warning' })
        this.props.getVideoTagsReload()
      })
  }

  render () {
    return (
      <div className='controlContainer'>
        <div className='controlContainer-category'>
          {this.renderCategoryMenu()}
          {this.props.eventType === 'soccer' ? (
            <Link
              disabled={this.props.did === undefined}
              to={{ pathname: 'https://www.fussball.de/spiel/-/spiel/' + this.props.did }}
              target='_blank'
            >
              <button disabled={this.props.did === undefined} className='btn-link'>fussball.de</button>
            </Link>
          ) : null}
        </div>
        <div className='controlContainer-content'>
          {this.createDoubleButton()}
          {this.createSingleButton()}

          <div key='deleteLastTag' className='content-singleBtnDelete'>
            <button
              disabled={this.state.disableButtons}
              style={this.state.disableButtons ? { backgroundColor: 'black', cursor: 'default' } : {}}
              onClick={this.props.deleteLastTags}
            >
              {i18n.t('tagControl.deleteLast')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
