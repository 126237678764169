import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import LoginPage from './pages/login/login'
import Dashboard from './pages/dashboard/dashboard'
import Editor from './pages/editor/editor'
import Cookies from 'universal-cookie'
import i18n from 'i18next'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import jwt_decode from 'jwt-decode'

import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'

const cookies = new Cookies()

class Router extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: cookies.get('email'),
      pwMd5: cookies.get('password'),
      loggedIn: false,
      editorActiv: !window.location.pathname.search('/match/')
    }

    // this.checkLogin = this.checkLogin.bind(this)
    this.handleEditorActive = this.handleEditorActive.bind(this)
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken()
        const encodeUser = jwt_decode(userToken)
        this.setState({ loggedIn: true, user: encodeUser, email: encodeUser.email })
      }
    })
  }

  handleEditorActive (value) {
    this.setState({
      editorActiv: value
    })
  }

  checkLogin = (check) => {
    if (check) {
      this.setState({
        loggedIn: true
      })
    }else {
      this.setState({
        loggedIn: false,
        mail: undefined,
        user: undefined
      })
    }
  }
  
  /*async checkLogin (loginData, logout, refresh) {
    Helper.setLogin(loginData.email, loginData.pass)

    if (logout) {
      cookies.remove('email')
      cookies.remove('password')
      this.setState({
        pwMd5: undefined,
        loggedIn: false
      })
      return null
    }

    try {
      this.setState({
        loggedIn: refresh
      })
      await Helper.apiGet(Discovery.API_USER + '/login/')
      if (!refresh) {
        mobiscroll.toast({ message: i18n.t('login.messages.success') })
        cookies.set('email', loginData.email)
        cookies.set('password', loginData.pass)
      }
      this.setState({
        email: loginData.email,
        pwMd5: loginData.pass,
        loggedIn: true
      })
    } catch (err) {
      mobiscroll.toast({
        color: 'warning',
        message: i18n.t('login.messages.failed') + ' ' + err
      })
      cookies.remove('email')
      cookies.remove('password')
      this.setState({
        pwMd5: undefined,
        loggedIn: false
      })
    }
  }*/

  render () {
    return (
      <BrowserRouter>
        <mobiscroll.Page className='app-page'>
          {this.state.loggedIn ? (
            <>
              <Switch>
                <Route path="/" exact render={() => <Dashboard />} />
                <Route path="/match/" render={(props) => <Editor {...props} onEditorActive={this.handleEditorActive} user={this.state.user} />} />
                <Route path="/login" render={() => <LoginPage loggedIn={this.state.loggedIn} email={this.state.email} loginCallback={this.checkLogin} />} />
                <Redirect to="/" />
              </Switch>
              {!this.state.editorActiv ? (
                <mobiscroll.BottomNav>
                  <mobiscroll.NavItem to="/" exact icon="home">
                    {i18n.t("nav.dashboard")}
                  </mobiscroll.NavItem>
                  <mobiscroll.NavItem to="/login" icon="user4">
                    {i18n.t("nav.account")}
                  </mobiscroll.NavItem>
                </mobiscroll.BottomNav>
              ) : null}
            </>
          ) : (
            <Switch>
              <Route
                path="/"
                component={() => {
                  return <LoginPage loggedIn={this.state.loggedIn} email={this.state.email} loginCallback={this.checkLogin} />;
                }}
              />
            </Switch>
          )}
        </mobiscroll.Page>
      </BrowserRouter>
    )
  }
}

export default Router
