import React from 'react'
import moment from 'moment'
import mobiscroll from '@mobiscroll/react'
import Loader from '../../../helper/loadingSpinner'
import i18n from 'i18next'

import { deleteTag, postSwapTags, postTag, deleteAiTag, postAiTagConfirmDenied } from '../../../../api/api-tag'
import { postMetaId } from "../../../../api/api-video";
import { EVENTTYPELIST } from '../../tagConfig'

import './sidebar.scss'


export default class Sidebar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      disableScrollTo: false,
      disableSwap: false,
      disableAiSwap: false,
      active: {}
    }

    this.setTagActive = this.setTagActive.bind(this)
    this.handleSwapTeamTags = this.handleSwapTeamTags.bind(this)
    this.handleSwapTeamAiTags = this.handleSwapTeamAiTags.bind(this)
    this.handleHighlightTag = this.handleHighlightTag.bind(this)
  }

  
  handlePostTagConfirmDenied = (aiTag, state) => {  
    return postAiTagConfirmDenied(state, aiTag.PartitionKey, aiTag.RowKey, this.props.user, this.checkClubALeft()).then(res => {
      this.props.getVideoTagsReload()
    })
  }

  getGenerateCard () {
    return this.props.tags.map((itm, index) => {
      const checkActive = (this.props.activeTag && this.props.activeTag.RowKey === itm.RowKey) || this.state.active[itm.RowKey]
      let checkForClub
      let checkForClubClass = 'sidebar-item center'
      let color = 'center'

      const checkTeamA = itm.eventType === 0 || itm.eventType === 260 || itm.eventType === 262 || itm.eventType === 264 || itm.eventType === 420 || itm.eventType === 422 || itm.eventType === 424 || itm.eventType === 426 || itm.eventType === 428
      const checkTeamB = itm.eventType === 1 || itm.eventType === 261 || itm.eventType === 263 || itm.eventType === 265 || itm.eventType === 421 || itm.eventType === 423 || itm.eventType === 425 || itm.eventType === 427 || itm.eventType === 429

      if (itm.name && (itm.name.search('Heimclub') >= 0 || checkTeamA)) {
        checkForClub = 'home'
        checkForClubClass = 'sidebar-item'
        color = this.props.teamColor.home // "green";
      }

      if (itm.name && (itm.name.search('Gegner') >= 0 || checkTeamB)) {
        checkForClub = 'guest'
        checkForClubClass = 'sidebar-item right'
        color = this.props.teamColor.guest // "red";
      }

      if (checkActive) {
        checkForClubClass = checkForClubClass + ' sidebar-item-open'
      }

      if (itm.level === 1) {
        checkForClubClass = checkForClubClass + ' activeHighlight'
      }

      if(itm.state === 'denied') {
        checkForClubClass = checkForClubClass + ' aiTag-denied'
      } else if(itm.state === 'confirmed') {
        checkForClubClass = checkForClubClass + ' aiTag-confirmed'
      }

      return (
        <div key={index} style={{ display: 'flex' }}>
          {itm.fromAispotter ? (
            <div className='aiTag'>AI</div>
          ) : null}

          {itm._aiTag ? (
            <div className='aiTag' style={itm.state !== 'unconfirmed' ? { opacity: 0.2 } : {}}>AI</div>
          ) : null}
          <div
            className={
              !this.state['active-' + itm.RowKey]
                ? checkForClubClass
                : this.state['activeAnimation-' + itm.RowKey]
                  ? checkForClubClass + ' slide-left'
                  : checkForClubClass + ' scale-up-center'
            }
            id={checkActive ? 'item-active' : ''}
            onClick={() => {
              this.setTagActive(itm)
            }}
          >
            <div className='item-colorBox' style={checkForClub === 'home' ? { backgroundColor: color } : null} />

            <div className='item-name'>{i18n.exists('eventTypeGeneric.' + this.props.eventType + '.' + itm.eventType) ? i18n.t('eventTypeGeneric.' + this.props.eventType + '.' + itm.eventType) : i18n.t('eventType.' + itm.eventType)}</div>
            {/* <div className='item-name'>{itm.identifier ? i18n.t('eventTypeGeneric.' + itm.identifier + '.' + itm.eventType) : i18n.t('eventType.' + itm.eventType)}</div> */}

            <div
              className='item-time'
              onClick={() => {
                this.props.onSeekTo(itm.timestamp)
              }}
            >
              {moment.utc(itm.timestamp * 1000).format('HH:mm:ss')}
            </div>
            <div className='item-colorBox' style={checkForClub === 'guest' ? { backgroundColor: color } : null} />
            <div className='item-btnBar'>
              {itm._aiTag ? null : (
                <div className='btnBar-btn btn-delete' onClick={() => this.handleDeleteTag(itm)}>
                  <span title={i18n.t('sidebar.btn.delete')} className='mbsc-ic mbsc-ic-remove' />
                </div>
              )}
              {itm._aiTag ? (
                <>
                  <div className={itm.state === 'denied' ? 'btnBar-btn btnBar-disabled' : 'btnBar-btn'} onClick={() => this.handlePostTagConfirmDenied(itm, 'denied')}>
                    <span style={{ fontSize: 10 }} className="mbsc-ic mbsc-ic-close" />
                  </div>
                  <div className={itm.state === 'confirmed' ? 'btnBar-btn btnBar-disabled' : 'btnBar-btn'} onClick={() => this.handlePostTagConfirmDenied(itm, 'confirmed')}>
                    <span className="mbsc-ic mbsc-ic-checkmark" />
                  </div>
                </>
              ) : (
                <div
                  className={itm.level !== 1 ? 'btnBar-btn btn-Highlight' : 'btnBar-btn btn-Highlight  btnBar-btnGetActive'}
                  onClick={() => this.handleHighlightTag(itm)}
                >
                  <span title={i18n.t('sidebar.btn.highlight')} className='mbsc-ic mbsc-ic-material-star' />
                </div>
              )}

              
              {itm._aiTag ? null : (
                <div className='btnBar-btn'>{i18n.t('sidebar.btn.goalOfWeek')}</div>
              )}
            </div>
          </div>
        </div>

      )
    })
  }

  setTagActive (itm) {
    this.setState({
      active: {
        // ...this.state.active,
        [itm.RowKey]: this.state.active[itm.RowKey] ? undefined : true
      }
    })
  }

  handleDeleteTag (itm) {
    this.setState({
      ['active-' + itm.RowKey]: true,
      disableScrollTo: true
    })

    if(itm._aiTag) {
      deleteAiTag(itm.PartitionKey, itm.RowKey, this.props.user).then((res) => {
        this.handleResponseDeleteTag(res, itm)
      })
    } else {
      deleteTag(itm.PartitionKey, itm.RowKey, this.props.user).then((res) => {
        this.handleResponseDeleteTag(res, itm)
      })
    }
  }

  handleResponseDeleteTag = (res, tag) => {
    if (res.status === 200) {
      // tag delet
      this.setState({
        ['activeAnimation-' + tag.RowKey]: true
      })
      mobiscroll.toast({ message: i18n.t('sidebar.messages.successDeleteTag'), display: 'bottom' })
      this.props.getVideoTagsReload()
      setTimeout(
        function () {
          this.setState({
            ['active-' + tag.RowKey]: undefined,
            ['activeAnimation-' + tag.RowKey]: undefined
          })
        }.bind(this),
        1000
      )
    } else {
      // tag delet fail
      this.setState({
        ['active-' + tag.RowKey]: undefined
      })
      mobiscroll.toast({ message: i18n.t('sidebar.messages.errorDeleteTag'), display: 'bottom', color: 'warning' })
    }
  }

  handleSwapTeamTags () {
    this.setState({
      disableSwap: true
    })
    this.props.onLoadingState()
    postSwapTags(this.props.matchId, this.props.user)
      .then((res) => {
        mobiscroll.toast({ message: i18n.t('sidebar.messages.successSwap'), display: 'bottom' })
        this.setState({
          disableSwap: false
        })
        this.props.getVideoTagsReload()
      })
      .catch((err) => {
        mobiscroll.toast({ message: i18n.t('messages.error') + ' ' + err, display: 'bottom', color: 'warning' })
        this.setState({
          disableSwap: false
        })
        this.props.getVideoTagsReload()
      })
  }
  
  handleSwapTeamAiTags (left) {
    this.setState({
      disableAiSwap: true
    })
    this.props.onLoadingState()

    const body = {
      RowKey: String(this.props.matchId),
      clubALeft: left
    }

    postMetaId(body, this.props.matchId).then(res => {
      if (res.status === 200) {
        mobiscroll.toast({ message: i18n.t('sidebar.messages.successSwap'), display: 'bottom' })
      } else {
        mobiscroll.toast({ message: i18n.t('messages.error'), display: 'bottom' })
      }
      this.setState({
        disableAiSwap: false
      })
      this.props.getVideoInformationsAndTags()
    }).catch(err => {
      mobiscroll.toast({ message: i18n.t('messages.error') + ' ' + err, display: 'bottom', color: 'warning' })
      this.setState({
        disableAiSwap: false
      })
      this.props.getVideoInformationsAndTags()
    })
  }

  handleHighlightTag (tag) {
    const checkHighlightValue = tag.level === 0 ? 1 : 0
    const body = { level: checkHighlightValue }

    postTag(body, tag.PartitionKey, tag.RowKey, this.props.user)
      .then((res) => {
        mobiscroll.toast({
          message: body.level === 1 ? i18n.t('sidebar.messages.setHighlightTag') : i18n.t('sidebar.messages.removeHighlightTag'),
          display: 'bottom'
        })
        this.props.getVideoTagsReload()
      })
      .catch((err) => {
        mobiscroll.toast({ message: err, display: 'bottom', color: 'warning' })
        this.props.getVideoTagsReload()
      })
  }

  componentDidUpdate (prevProps) {
    const prevPropsRowKey = (prevProps.activeTag && prevProps.activeTag.RowKey) || 0
    const propsRowKey = (this.props.activeTag && this.props.activeTag.RowKey) || 0
    if (this.state.disableScrollTo && propsRowKey !== prevPropsRowKey) {
      this.setState({
        disableScrollTo: false
      })
    }

    if (!this.state.disableScrollTo) {
      if (
        document.getElementById('item-active') &&
        document.getElementById('item-active').offsetTop - 62 !== document.getElementById('container-sidebar').offsetTop
      ) {
        var myElement = document.getElementById('item-active')
        var topPos = myElement.offsetTop
        document.getElementById('container-sidebar').scrollTop = topPos - 62
      } else {
        document.getElementById('container-sidebar').scrollTop = 0
      }
    }
  }

  shouldComponentUpdate (prevProps, prevState) {
    return (
      prevProps.activeTag !== this.props.activeTag || prevProps.tags !== this.props.tags || prevState.active !== this.state.active
    )
  }

  checkClubALeft = () => {
    const clubALeftVote = this.props.matchInfo.clubALeftVote
    let clubALeft = this.props.matchInfo.clubALeft
    if(this.props.matchInfo.clubALeft === undefined && clubALeftVote !== undefined) {
      clubALeft = clubALeftVote
    }
    return clubALeft
  }

  render () {
    const { disableSwap, disableAiSwap } = this.state
    let clubALeft = this.checkClubALeft()

    return (
      <div className='sidebar'>
        <div style={{ justifyContent: 'normal', overflow: 'auto', flexGrow: 2 }}>{this.getGenerateCard()}</div>

        <div className='sidebar-doubleInfoText'>
          {i18n.t('sidebar.clubALeftInfoText')}
        </div>
        <div className='sidebar-doubleBtn'>
          <div 
            className={clubALeft === true ? 'doubleBtn-btn active' : 'doubleBtn-btn'}
            onClick={clubALeft === true && !disableAiSwap ? null : () => this.handleSwapTeamAiTags(true)}
          >
            <span className='mbsc-ic mbsc-ic-arrow-left2' /> {i18n.t('sidebar.clubALeft')}
          </div>
          <div
            className={clubALeft === false ? 'doubleBtn-btn active' : 'doubleBtn-btn'}
            onClick={clubALeft === false && !disableAiSwap ? null : () => this.handleSwapTeamAiTags(false)}
          >
            {i18n.t('sidebar.clubARight')} <span className='mbsc-ic mbsc-ic-arrow-right2' />
          </div>
        </div>

        <div
          style={this.props.tags.length <= 0 || disableSwap ? { pointerEvents: 'none', width: this.props.sidebarwidth } : { width: this.props.sidebarwidth }}
          className='sidebar-bottomBtn'
          onClick={disableSwap ? null : this.handleSwapTeamTags}
        >
          {disableSwap ? (
            <div className='loadingSwap'>
              <Loader />
            </div>
          ) : (
            <>
              <span className='mbsc-ic mbsc-ic-material-repeat' />
              {i18n.t('sidebar.swapTeam')}
            </>
          )}
        </div>
      </div>
    )
  }
}
