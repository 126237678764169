import React from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import AppHeader from '../helper/appHeader'

export default class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.refLogin = React.createRef()
  }

  componentDidMount () {
    if (this.refLogin.current) {
      this.refLogin.current.addEventListener('loggedOut', async () => {
        await this.props.loginCallback(false)
        // mobiscroll.toast({ message: i18n.t('login.toast.logoutSuccessful') })
      })
    }
  }

  checkLanguage (lang) {
    const findMore = lang.search('-')
    if (findMore !== -1) {
      const split = lang.split('-')
      return split[0]
    }
    return lang
  }

  render () {
    return (
      <AppHeader title={this.props.loggedIn ? i18n.t('nav.account') : i18n.t('nav.login')} mbscCol='mbsc-col-sm-9 mbsc-col-md-5'>
        <div style={{
          height: '560px'
        }}
        >
          <aisw-user ref={this.refLogin} language={this.checkLanguage(i18n.language)} />
        </div>
      </AppHeader>
    )
  }
}
